import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/auth-context';
import { LISTING_REQUIREMENTS } from '@/types/exhibition';
import { useMathCaptcha } from '@/lib/captcha';
import { uploadExhibitionImage, createExhibition, getCategories, getRegions } from '@/lib/api';
import { Calendar, Clock, MapPin, Building, Upload, AlertCircle, Check } from 'lucide-react';

interface FormData {
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  location: string;
  venue: string;
  categoryId: string;
  regionId: string;
  organizer: string;
  image: File | null;
  captchaAnswer: string;
  agreeToTerms: boolean;
}

export function ListExhibition() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { captcha, refreshCaptcha } = useMathCaptcha();
  const [categories, setCategories] = useState<Array<{ id: string; name: string }>>([]);
  const [regions, setRegions] = useState<Array<{ id: string; name: string }>>([]);

  const [formData, setFormData] = useState<FormData>({
    name: '',
    description: '',
    startDate: '',
    endDate: '',
    location: '',
    venue: '',
    categoryId: '',
    regionId: '',
    organizer: '',
    image: null,
    captchaAnswer: '',
    agreeToTerms: false
  });

  const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});
  const [imagePreview, setImagePreview] = useState<string>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/signin?next=/list-exhibition');
      return;
    }

    // Load categories and regions
    const loadData = async () => {
      try {
        const [categoriesData, regionsData] = await Promise.all([
          getCategories(),
          getRegions()
        ]);
        setCategories(categoriesData);
        setRegions(regionsData);
      } catch (error) {
        console.error('Error loading form data:', error);
      }
    };

    loadData();
  }, [user, navigate]);

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof FormData, string>> = {};

    if (!formData.name) {
      newErrors.name = 'Title is required';
    } else if (formData.name.length < LISTING_REQUIREMENTS.name.minLength) {
      newErrors.name = `Title must be at least ${LISTING_REQUIREMENTS.name.minLength} characters`;
    } else if (formData.name.length > LISTING_REQUIREMENTS.name.maxLength) {
      newErrors.name = `Title cannot exceed ${LISTING_REQUIREMENTS.name.maxLength} characters`;
    }

    if (!formData.description) {
      newErrors.description = 'Description is required';
    } else if (formData.description.length < LISTING_REQUIREMENTS.description.minLength) {
      newErrors.description = `Description must be at least ${LISTING_REQUIREMENTS.description.minLength} characters`;
    } else if (formData.description.length > LISTING_REQUIREMENTS.description.maxLength) {
      newErrors.description = `Description cannot exceed ${LISTING_REQUIREMENTS.description.maxLength} characters`;
    }

    if (!formData.startDate) {
      newErrors.startDate = 'Start date is required';
    }
    if (!formData.endDate) {
      newErrors.endDate = 'End date is required';
    }
    if (formData.startDate && formData.endDate && new Date(formData.startDate) > new Date(formData.endDate)) {
      newErrors.endDate = 'End date must be after start date';
    }

    if (!formData.location) {
      newErrors.location = 'Location is required';
    }

    if (!formData.venue) {
      newErrors.venue = 'Venue is required';
    }

    if (!formData.categoryId) {
      newErrors.categoryId = 'Category is required';
    }

    if (!formData.regionId) {
      newErrors.regionId = 'Region is required';
    }

    if (!formData.organizer) {
      newErrors.organizer = 'Organizer is required';
    }

    // Optional image validation
    if (formData.image) {
      const { maxSize, minWidth, minHeight, formats } = LISTING_REQUIREMENTS.image;
      const fileSize = formData.image.size / (1024 * 1024); // Convert to MB
      const fileType = formData.image.type.split('/')[1];

      if (!formats.includes(fileType)) {
        newErrors.image = `Image must be in ${formats.join(', ')} format`;
      }
      if (fileSize > maxSize) {
        newErrors.image = `Image size must not exceed ${maxSize}MB`;
      }
    }

    if (!formData.captchaAnswer) {
      newErrors.captchaAnswer = 'Please solve the CAPTCHA';
    } else if (parseInt(formData.captchaAnswer) !== captcha.answer) {
      newErrors.captchaAnswer = 'Incorrect CAPTCHA answer';
      refreshCaptcha();
    }

    // Terms validation
    if (!formData.agreeToTerms) {
      newErrors.agreeToTerms = 'You must agree to the terms';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setFormData({ ...formData, image: file });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm() || !user) return;

    try {
      setLoading(true);

      // Upload image
      let imageUrl = null;
      if (formData.image) {
        imageUrl = await uploadExhibitionImage(formData.image, user.id);
      }

      // Create exhibition with all required fields
      await createExhibition({
        name: formData.name,
        description: formData.description,
        image_url: imageUrl,
        start_date: formData.startDate,
        end_date: formData.endDate,
        location: formData.location,
        venue: formData.venue,
        category_id: formData.categoryId,
        region_id: formData.regionId,
        organizer: formData.organizer,
        created_by: user.id
      });

      // Navigate to exhibitions page
      navigate('/exhibitions');
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrors(prev => ({
        ...prev,
        submit: 'Failed to create exhibition. Please try again.'
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-3xl">
          <div className="mb-8">
            <h1 className="text-3xl font-bold text-gray-900">List Your Exhibition</h1>
            <p className="mt-2 text-gray-600">
              Share your exhibition with our global community of event professionals and attendees.
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-8">
            {/* Basic Information */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-xl font-semibold text-gray-900">Basic Information</h2>
              
              <div className="space-y-6">
                {/* Title */}
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                    Exhibition Title
                    <span className="ml-1 text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="name"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  {errors.name && (
                    <p className="mt-1 text-sm text-red-600">{errors.name}</p>
                  )}
                  <p className="mt-1 text-sm text-gray-500">
                    {formData.name.length}/{LISTING_REQUIREMENTS.name.maxLength} characters
                  </p>
                </div>

                {/* Description */}
                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                    Description
                    <span className="ml-1 text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="description"
                      rows={6}
                      value={formData.description}
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                      className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  {errors.description && (
                    <p className="mt-1 text-sm text-red-600">{errors.description}</p>
                  )}
                  <p className="mt-1 text-sm text-gray-500">
                    {formData.description.length}/{LISTING_REQUIREMENTS.description.maxLength} characters
                  </p>
                </div>

                {/* Dates */}
                <div className="grid gap-6 md:grid-cols-2">
                  <div>
                    <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
                      Start Date
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <div className="relative">
                        <Calendar className="pointer-events-none absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                          type="date"
                          id="startDate"
                          value={formData.startDate}
                          onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                          className="block w-full rounded-md border border-gray-300 pl-10 pr-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    {errors.startDate && (
                      <p className="mt-1 text-sm text-red-600">{errors.startDate}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                      End Date
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <div className="relative">
                        <Calendar className="pointer-events-none absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                          type="date"
                          id="endDate"
                          value={formData.endDate}
                          onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                          className="block w-full rounded-md border border-gray-300 pl-10 pr-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    {errors.endDate && (
                      <p className="mt-1 text-sm text-red-600">{errors.endDate}</p>
                    )}
                  </div>
                </div>

                {/* Location & Venue */}
                <div className="grid gap-6 md:grid-cols-2">
                  <div>
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                      Location
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <div className="relative">
                        <MapPin className="pointer-events-none absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                          type="text"
                          id="location"
                          value={formData.location}
                          onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                          placeholder="City, Country"
                          className="block w-full rounded-md border border-gray-300 pl-10 pr-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    {errors.location && (
                      <p className="mt-1 text-sm text-red-600">{errors.location}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="venue" className="block text-sm font-medium text-gray-700">
                      Venue
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <div className="relative">
                        <Building className="pointer-events-none absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        <input
                          type="text"
                          id="venue"
                          value={formData.venue}
                          onChange={(e) => setFormData({ ...formData, venue: e.target.value })}
                          placeholder="Convention Center"
                          className="block w-full rounded-md border border-gray-300 pl-10 pr-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                        />
                      </div>
                    </div>
                    {errors.venue && (
                      <p className="mt-1 text-sm text-red-600">{errors.venue}</p>
                    )}
                  </div>
                </div>

                {/* Category & Region */}
                <div className="grid gap-6 md:grid-cols-2">
                  <div>
                    <label htmlFor="category" className="block text-sm font-medium text-gray-700">
                      Category
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="category"
                        value={formData.categoryId}
                        onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
                        className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                      >
                        <option value="">Select a category</option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.categoryId && (
                      <p className="mt-1 text-sm text-red-600">{errors.categoryId}</p>
                    )}
                  </div>

                  <div>
                    <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                      Region
                      <span className="ml-1 text-red-500">*</span>
                    </label>
                    <div className="mt-1">
                      <select
                        id="region"
                        value={formData.regionId}
                        onChange={(e) => setFormData({ ...formData, regionId: e.target.value })}
                        className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                      >
                        <option value="">Select a region</option>
                        {regions.map((region) => (
                          <option key={region.id} value={region.id}>
                            {region.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.regionId && (
                      <p className="mt-1 text-sm text-red-600">{errors.regionId}</p>
                    )}
                  </div>
                </div>

                {/* Organizer */}
                <div>
                  <label htmlFor="organizer" className="block text-sm font-medium text-gray-700">
                    Organizer
                    <span className="ml-1 text-red-500">*</span>
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      id="organizer"
                      value={formData.organizer}
                      onChange={(e) => setFormData({ ...formData, organizer: e.target.value })}
                      className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  {errors.organizer && (
                    <p className="mt-1 text-sm text-red-600">{errors.organizer}</p>
                  )}
                </div>
              </div>
            </div>

            {/* Image Upload */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-xl font-semibold text-gray-900">Exhibition Image</h2>
              
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Upload className="h-5 w-5 text-gray-400" />
                  <span className="text-sm text-gray-500">
                    Upload a high-quality image ({LISTING_REQUIREMENTS.image.formats.join(', ')})
                  </span>
                </div>

                <div className="rounded-lg border-2 border-dashed border-gray-300 p-6">
                  <input
                    type="file"
                    id="image"
                    accept={LISTING_REQUIREMENTS.image.formats.map(format => `.${format}`).join(',')}
                    onChange={handleImageChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="image"
                    className="flex cursor-pointer flex-col items-center justify-center"
                  >
                    {imagePreview ? (
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className="mb-4 h-48 w-full rounded-lg object-cover"
                      />
                    ) : (
                      <div className="text-center">
                        <Upload className="mx-auto h-12 w-12 text-gray-400" />
                        <p className="mt-2 text-sm text-gray-500">
                          Click to upload or drag and drop
                        </p>
                      </div>
                    )}
                  </label>
                </div>

                {errors.image && (
                  <p className="text-sm text-red-600">{errors.image}</p>
                )}

                <ul className="space-y-2 text-sm text-gray-500">
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-green-500" />
                    Maximum file size: {LISTING_REQUIREMENTS.image.maxSize}MB
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-green-500" />
                    Minimum dimensions: {LISTING_REQUIREMENTS.image.minWidth}x{LISTING_REQUIREMENTS.image.minHeight}px
                  </li>
                  <li className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-green-500" />
                    Aspect ratio: {LISTING_REQUIREMENTS.image.aspectRatio}
                  </li>
                </ul>
              </div>
            </div>

            {/* CAPTCHA */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-xl font-semibold text-gray-900">Verification</h2>
              
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    {captcha.question}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      value={formData.captchaAnswer}
                      onChange={(e) => setFormData({ ...formData, captchaAnswer: e.target.value })}
                      className="block w-full rounded-md border border-gray-300 px-4 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  {errors.captchaAnswer && (
                    <p className="mt-1 text-sm text-red-600">{errors.captchaAnswer}</p>
                  )}
                </div>

                <button
                  type="button"
                  onClick={refreshCaptcha}
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  Try a different question
                </button>
              </div>
            </div>

            {/* Terms and Conditions */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h2 className="mb-6 text-xl font-semibold text-gray-900">Terms and Conditions</h2>
              
              <div className="space-y-4">
                <div className="rounded-lg bg-gray-50 p-4">
                  <ul className="list-inside list-disc space-y-2 text-sm text-gray-600">
                    {LISTING_REQUIREMENTS.terms.map((term, index) => (
                      <li key={index}>{term}</li>
                    ))}
                  </ul>
                </div>

                <div className="flex items-start">
                  <div className="flex h-5 items-center">
                    <input
                      id="terms"
                      type="checkbox"
                      checked={formData.agreeToTerms}
                      onChange={(e) => setFormData({ ...formData, agreeToTerms: e.target.checked })}
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                  </div>
                  <label htmlFor="terms" className="ml-2 text-sm text-gray-600">
                    I agree to the terms and conditions
                  </label>
                </div>
                {errors.agreeToTerms && (
                  <p className="text-sm text-red-600">{errors.agreeToTerms}</p>
                )}
              </div>
            </div>

            {/* Submit Button */}
            <div className="flex justify-end space-x-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => navigate(-1)}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit Exhibition'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}