import { supabase } from './supabase';
import type { Exhibition, Category, Region, ExhibitionStatistics, SubscriptionDetails, Invoice } from '@/types/exhibition';

export async function downloadInvoice(invoiceId: string): Promise<string> {
  const { data, error } = await supabase
    .from('invoices')
    .select('pdf_url')
    .eq('id', invoiceId)
    .single();

  if (error) throw error;
  if (!data?.pdf_url) throw new Error('Invoice PDF not found');

  return data.pdf_url;
}

export async function getInvoices(): Promise<Invoice[]> {
  const { data, error } = await supabase
    .from('invoices')
    .select('*')
    .order('issued_date', { ascending: false });

  if (error) throw error;
  return data || [];
}

export async function getUserSubscriptionDetails(): Promise<SubscriptionDetails> {
  const { data, error } = await supabase
    .rpc('get_user_subscription_details');

  if (error) {
    console.error('Error fetching subscription details:', error);
    throw error;
  }

  return data;
}

export async function cancelSubscription(subscriptionId: string) {
  const { error } = await supabase
    .from('subscriptions')
    .update({ cancel_at_period_end: true })
    .eq('id', subscriptionId);

  if (error) {
    console.error('Error canceling subscription:', error);
    throw error;
  }
}

export async function getExhibitions() {
  const { data, error } = await supabase
    .from('exhibitions')
    .select(`
      *,
      categories (name),
      regions (name)
    `)
    .eq('status', 'active')
    .order('date', { ascending: true });

  if (error) {
    console.error('Error fetching exhibitions:', error);
    throw error;
  }

  return data || [];
}

export async function getCategories(): Promise<Category[]> {
  const { data, error } = await supabase
    .from('categories')
    .select('*')
    .order('name');

  if (error) {
    console.error('Error fetching categories:', error);
    throw error;
  }

  return data || [];
}

export async function getRegions(): Promise<Region[]> {
  const { data, error } = await supabase
    .from('regions')
    .select('*')
    .order('name');

  if (error) {
    console.error('Error fetching regions:', error);
    throw error;
  }

  return data || [];
}

export async function uploadExhibitionImage(file: File, userId: string) {
  const fileExt = file.name.split('.').pop();
  const fileName = `${userId}/${Math.random().toString(36).slice(2)}.${fileExt}`;

  const { error: uploadError } = await supabase.storage
    .from('exhibition-images')
    .upload(fileName, file);

  if (uploadError) {
    console.error('Error uploading image:', uploadError);
    throw new Error('Failed to upload image');
  }

  const { data: { publicUrl } } = supabase.storage
    .from('exhibition-images')
    .getPublicUrl(fileName);

  return publicUrl;
}

export async function createExhibition(data: {
  name: string;
  description: string;
  image_url: string;
  start_date: string;
  end_date: string;
  location: string;
  venue: string;
  category_id: string;
  region_id: string;
  organizer: string;
  created_by: string;
}) {
  // Format the date field as YYYY-MM-DD
  const date = new Date(data.start_date).toISOString().split('T')[0];
  
  // Calculate duration
  const start = new Date(data.start_date);
  const end = new Date(data.end_date);
  const days = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24));
  const duration = `${days} day${days > 1 ? 's' : ''}`;

  const { error } = await supabase
    .from('exhibitions')
    .insert([{
      ...data,
      date, // Add formatted date field
      duration, // Add calculated duration
      cycle: 'One-time', // Default cycle
      status: 'active' // Default status
    }]);

  if (error) {
    console.error('Error creating exhibition:', error);
    throw error;
  }
}

export async function getExhibitionById(id: string) {
  const { data, error } = await supabase
    .from('exhibitions')
    .select(`
      *,
      categories (name),
      regions (name)
    `)
    .eq('id', id)
    .single();

  if (error) {
    console.error('Error fetching exhibition:', error);
    throw error;
  }

  return data;
}

export async function getSimilarExhibitions(exhibitionId: string) {
  const exhibition = await getExhibitionById(exhibitionId);
  if (!exhibition) return [];

  const { data, error } = await supabase
    .from('exhibitions')
    .select(`
      *,
      categories (name),
      regions (name)
    `)
    .eq('category_id', exhibition.category_id)
    .eq('status', 'active')
    .neq('id', exhibitionId)
    .limit(3);

  if (error) {
    console.error('Error fetching similar exhibitions:', error);
    throw error;
  }

  return data || [];
}

export async function searchExhibitions(
  searchTerm: string = '',
  filters: {
    regionId?: string;
    categoryId?: string;
    status?: string;
    filter?: string;
  } = {}
) {
  const { regionId, categoryId, status, filter } = filters;
  
  let query = supabase
    .from('exhibitions')
    .select(`
      *,
      categories (name),
      regions (name)
    `);

  // Apply text search if provided
  if (searchTerm) {
    query = query.or(`name.ilike.%${searchTerm}%,description.ilike.%${searchTerm}%`);
  }

  // Apply region filter
  if (regionId) {
    query = query.eq('region_id', regionId);
  }

  // Apply category filter
  if (categoryId) {
    query = query.eq('category_id', categoryId);
  }

  // Apply status filter
  if (status && status !== 'all') {
    query = query.eq('status', status);
  } else {
    // Default to active exhibitions only
    query = query.eq('status', 'active');
  }

  // Apply special filters
  if (filter) {
    switch (filter) {
      case 'industry':
        query = query.order('category_id');
        break;
      case 'location':
        query = query.order('region_id');
        break;
      case 'upcoming':
        query = query.gt('date', new Date().toISOString().split('T')[0]);
        break;
    }
  }

  // Always order by date as default
  query = query.order('date');

  const { data, error } = await query;

  if (error) {
    console.error('Error searching exhibitions:', error);
    throw error;
  }

  return data || [];
}

export async function getUpcomingExhibitions(limit: number = 5) {
  const { data, error } = await supabase
    .from('exhibitions')
    .select(`
      *,
      categories (name),
      regions (name)
    `)
    .eq('status', 'active')
    .gt('date', new Date().toISOString().split('T')[0])
    .order('date')
    .limit(limit);

  if (error) {
    console.error('Error fetching upcoming exhibitions:', error);
    throw error;
  }

  return data || [];
}

export async function getExhibitionStatistics(): Promise<ExhibitionStatistics> {
  const { data, error } = await supabase
    .from('exhibitions')
    .select(`
      *,
      categories (name),
      regions (name)
    `);

  if (error) {
    console.error('Error fetching exhibition statistics:', error);
    throw error;
  }

  // Calculate statistics from raw data
  const exhibitions = data || [];
  const activeExhibitions = exhibitions.filter(e => e.status === 'active');

  // Count by region
  const regionCounts = activeExhibitions.reduce((acc, exhibition) => {
    const regionName = exhibition.regions?.name || 'Unknown';
    acc[regionName] = (acc[regionName] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Count by category
  const categoryCounts = activeExhibitions.reduce((acc, exhibition) => {
    const categoryName = exhibition.categories?.name || 'Unknown';
    acc[categoryName] = (acc[categoryName] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  // Get top region and category
  const byRegion = Object.entries(regionCounts)
    .sort(([, a], [, b]) => b - a)
    .map(([region_name, count]) => ({ region_name, count }))[0];

  const byCategory = Object.entries(categoryCounts)
    .sort(([, a], [, b]) => b - a)
    .map(([category_name, count]) => ({ category_name, count }))[0];

  return {
    total_count: exhibitions.length,
    active_count: activeExhibitions.length,
    by_region: byRegion ? [byRegion] : [],
    by_category: byCategory ? [byCategory] : []
  };
}