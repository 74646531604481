import React from 'react';
import { Ban, Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import type { Exhibition } from '@/types/exhibition';

interface ListingsTableProps {
  listings: Exhibition[];
  searchTerm: string;
  filter: string;
  onSearchChange: (value: string) => void;
  onFilterChange: (value: string) => void;
  onRemove: (id: string) => void;
}

export function ListingsTable({
  listings,
  searchTerm,
  filter,
  onSearchChange,
  onFilterChange,
  onRemove
}: ListingsTableProps) {
  const filteredListings = listings.filter(listing => 
    (filter === 'all' || listing.status === filter) &&
    (searchTerm === '' || 
      listing.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      listing.location.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="mb-8">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-gray-900">Manage Listings</h2>
        <div className="flex space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search listings..."
              value={searchTerm}
              onChange={(e) => onSearchChange(e.target.value)}
              className="pl-10 pr-4 py-2 rounded-md border border-gray-300"
            />
          </div>
          <select
            value={filter}
            onChange={(e) => onFilterChange(e.target.value)}
            className="rounded-md border border-gray-300 px-4 py-2"
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="postponed">Postponed</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
      </div>

      <div className="overflow-hidden rounded-lg border border-gray-200 bg-white">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Exhibition
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Category
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Location
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {filteredListings.map((listing) => (
              <tr key={listing.id}>
                <td className="px-6 py-4">
                  <div className="flex items-center">
                    {listing.image_url ? (
                      <div className="flex items-center">
                      <img
                        src={listing.image_url}
                        alt={listing.name}
                        className="h-10 w-10 rounded-lg object-cover"
                      />
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{listing.name}</div>
                        <div className="text-sm text-gray-500">ID: {listing.id}</div>
                      </div>
                    </div>
                    ) : (
                      <div className="w-full">
                        <div className="flex h-10 items-center justify-center rounded-lg bg-blue-50">
                          <span className="font-bold text-gray-900">{listing.name}</span>
                        </div>
                        <div className="mt-1 text-center text-sm text-gray-500">ID: {listing.id}</div>
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                    listing.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : listing.status === 'postponed'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {listing.status.charAt(0).toUpperCase() + listing.status.slice(1)}
                  </span>
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {listing.categories?.name}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {listing.location}
                </td>
                <td className="px-6 py-4">
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => onRemove(listing.id)}
                    className="text-red-600 hover:text-red-700"
                  >
                    <Ban className="h-4 w-4 mr-2" />
                    Remove
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}