import React, { useState, useEffect, useRef } from 'react';
import { Search, Filter, Calendar, MapPin, ChevronDown, Clock, Repeat, Building } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link, useSearchParams } from 'react-router-dom';
import { getExhibitions, getCategories, getRegions, searchExhibitions, getExhibitionStatistics } from '@/lib/api';
import type { Exhibition, Category, Region, ExhibitionStatistics } from '@/types/exhibition';

export function ExhibitionDirectory() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [exhibitions, setExhibitions] = useState<Exhibition[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [regions, setRegions] = useState<Region[]>([]);
  const [statistics, setStatistics] = useState<ExhibitionStatistics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') || '');
  const [selectedCategory, setSelectedCategory] = useState(searchParams.get('category') || '');
  const [selectedRegion, setSelectedRegion] = useState(searchParams.get('region') || '');
  const [selectedStatus, setSelectedStatus] = useState(searchParams.get('status') || 'all');
  const [sortBy, setSortBy] = useState(searchParams.get('sort') || 'date');

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Focus search input if there's a filter parameter
    if (searchParams.get('filter') && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [searchParams]);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);
        setError(null);

        // Load all data in parallel
        const [categoriesData, regionsData, statsData, exhibitionsData] = await Promise.all([
          getCategories().catch(err => {
            console.error('Error loading categories:', err);
            return [];
          }),
          getRegions().catch(err => {
            console.error('Error loading regions:', err);
            return [];
          }),
          getExhibitionStatistics().catch(err => {
            console.error('Error loading statistics:', err);
            return null;
          }),
          getExhibitions().catch(err => {
            console.error('Error loading exhibitions:', err);
            return [];
          })
        ]);
        
        setCategories(categoriesData);
        setRegions(regionsData);
        setStatistics(statsData);
        setExhibitions(exhibitionsData);
      } catch (err) {
        console.error('Error loading exhibitions data:', err);
        setError('Failed to load exhibitions data. Please try again later.');
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    async function performSearch() {
      try {
        setLoading(true);
        setError(null);

        const filter = searchParams.get('filter');
        const data = await searchExhibitions(searchTerm, {
          regionId: selectedRegion,
          categoryId: selectedCategory,
          status: selectedStatus !== 'all' ? selectedStatus : undefined,
          filter
        });

        setExhibitions(data || []);
      } catch (err) {
        console.error('Error searching exhibitions:', err);
        setError('Failed to search exhibitions. Please try again.');
      } finally {
        setLoading(false);
      }
    }

    performSearch();
  }, [searchTerm, selectedCategory, selectedRegion, selectedStatus, searchParams]);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    updateSearchParams();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      updateSearchParams();
    }
  };

  const updateSearchParams = () => {
    const params = new URLSearchParams();
    if (searchTerm) params.set('search', searchTerm);
    if (selectedCategory) params.set('category', selectedCategory);
    if (selectedRegion) params.set('region', selectedRegion);
    if (selectedStatus !== 'all') params.set('status', selectedStatus);
    if (sortBy !== 'date') params.set('sort', sortBy);
    setSearchParams(params);
  };

  const sortedExhibitions = [...exhibitions].sort((a, b) => {
    if (sortBy === 'date') {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    }
    return a.name.localeCompare(b.name);
  });

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="container mx-auto px-4">
          <div className="mb-8 animate-pulse">
            <div className="h-8 w-1/3 rounded bg-gray-200" />
            <div className="mt-2 h-4 w-1/2 rounded bg-gray-200" />
          </div>
          <div className="mb-8 rounded-lg bg-white p-6 shadow-md">
            <div className="grid gap-4">
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className="h-10 rounded bg-gray-100" />
              ))}
            </div>
          </div>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {[1, 2, 3, 4, 5, 6].map((i) => (
              <div key={i} className="animate-pulse rounded-lg bg-white p-6 shadow-sm">
                <div className="mb-4 h-48 rounded bg-gray-100" />
                <div className="space-y-2">
                  <div className="h-6 w-3/4 rounded bg-gray-100" />
                  <div className="h-4 w-1/2 rounded bg-gray-100" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-gray-50">
        <div className="mx-auto max-w-md rounded-lg bg-white p-6 text-center shadow-lg">
          <div className="mb-4 text-red-500">
            <svg className="mx-auto h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <h3 className="mb-2 text-lg font-semibold text-gray-900">Error Loading Data</h3>
          <p className="mb-4 text-gray-600">{error}</p>
          <Button
            onClick={() => window.location.reload()}
            className="w-full"
          >
            Try Again
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        {/* Header with Statistics */}
        <div className="mb-8">
          <h1 className="mb-2 text-3xl font-bold text-gray-900">Exhibition Directory</h1>
          <p className="mb-6 text-gray-600">Discover and explore upcoming exhibitions worldwide</p>
          
          {statistics && (
            <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
              <div className="rounded-lg bg-white p-4 shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">Total Exhibitions</h3>
                <p className="mt-1 text-2xl font-semibold text-blue-600">
                  {statistics.total_count || 0}
                </p>
              </div>
              <div className="rounded-lg bg-white p-4 shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">Active Exhibitions</h3>
                <p className="mt-1 text-2xl font-semibold text-green-600">
                  {statistics.active_count || 0}
                </p>
              </div>
              <div className="rounded-lg bg-white p-4 shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">Top Region</h3>
                <p className="mt-1 text-2xl font-semibold text-purple-600">
                  {statistics.by_region?.[0]?.region_name || 'N/A'}
                </p>
              </div>
              <div className="rounded-lg bg-white p-4 shadow-sm">
                <h3 className="text-sm font-medium text-gray-500">Top Category</h3>
                <p className="mt-1 text-2xl font-semibold text-orange-600">
                  {statistics.by_category?.[0]?.category_name || 'N/A'}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Search and Filters */}
        <div className="mb-8 space-y-4 rounded-lg bg-white p-6 shadow-md">
          <form onSubmit={handleSearch}>
            <div className="relative">
              <input
                ref={searchInputRef}
                type="text"
                placeholder="Search exhibitions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 pl-10 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              />
              <button
                type="submit"
                className="absolute left-3 top-2.5 text-gray-400 hover:text-gray-600"
              >
                <Search className="h-5 w-5" />
              </button>
            </div>
          </form>

          <div className="grid gap-4 md:grid-cols-4">
            {/* Category Filter */}
            <div className="relative">
              <select
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  updateSearchParams();
                }}
                className="w-full appearance-none rounded-lg border border-gray-300 bg-white px-4 py-2 pr-8 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="">All Categories</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>

            {/* Region Filter */}
            <div className="relative">
              <select
                value={selectedRegion}
                onChange={(e) => {
                  setSelectedRegion(e.target.value);
                  updateSearchParams();
                }}
                className="w-full appearance-none rounded-lg border border-gray-300 bg-white px-4 py-2 pr-8 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="">All Regions</option>
                {regions.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
              <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>

            {/* Status Filter */}
            <div className="relative">
              <select
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target.value);
                  updateSearchParams();
                }}
                className="w-full appearance-none rounded-lg border border-gray-300 bg-white px-4 py-2 pr-8 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="all">All Statuses</option>
                <option value="active">Active</option>
                <option value="postponed">Postponed</option>
                <option value="cancelled">Cancelled</option>
              </select>
              <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>

            {/* Sort By */}
            <div className="relative">
              <select
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                  updateSearchParams();
                }}
                className="w-full appearance-none rounded-lg border border-gray-300 bg-white px-4 py-2 pr-8 focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
              >
                <option value="date">Sort by Date</option>
                <option value="name">Sort by Name</option>
              </select>
              <ChevronDown className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        </div>

        {/* Results */}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {sortedExhibitions.map((exhibition) => (
            <Link
              key={exhibition.id}
              to={`/exhibition/${exhibition.id}`}
              className="group overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm transition-shadow hover:shadow-md"
            >
              <div className="aspect-video overflow-hidden bg-blue-50">
                {exhibition.image_url ? (
                <img
                  src={exhibition.image_url}
                  alt={exhibition.name}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                ) : (
                <div className="flex h-full w-full items-center justify-center">
                  <span className="text-xl font-bold text-gray-900">{exhibition.name}</span>
                </div>
                )}
              </div>
              <div className="p-6">
                <div className="mb-2 flex items-center justify-between">
                  <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium ${
                    exhibition.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : exhibition.status === 'postponed'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {exhibition.status.charAt(0).toUpperCase() + exhibition.status.slice(1)}
                  </span>
                  <span className="text-sm font-medium text-blue-600">
                    {exhibition.categories?.name}
                  </span>
                </div>
                <h3 className="mb-2 text-xl font-bold text-gray-900">{exhibition.name}</h3>
                <p className="mb-4 text-sm text-gray-600 line-clamp-2">{exhibition.description}</p>
                <div className="mb-4 space-y-2">
                  <div className="flex items-center text-gray-600">
                    <Calendar className="mr-2 h-4 w-4" />
                    <span>{exhibition.date}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Clock className="mr-2 h-4 w-4" />
                    <span>{exhibition.duration}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Repeat className="mr-2 h-4 w-4" />
                    <span>{exhibition.cycle}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Building className="mr-2 h-4 w-4" />
                    <span>{exhibition.venue}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <MapPin className="mr-2 h-4 w-4" />
                    <span>{exhibition.location}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <Button variant="outline" size="sm">
                    View Details
                  </Button>
                  <span className="text-sm text-gray-500">{exhibition.regions?.name}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>

        {sortedExhibitions.length === 0 && (
          <div className="mt-8 text-center">
            <p className="text-lg text-gray-600">No exhibitions found matching your criteria.</p>
          </div>
        )}
      </div>
    </div>
  );
}