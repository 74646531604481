import React, { useState } from 'react';
import { Search } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { safeNavigate } from '@/lib/navigation';

export function HeroSection() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      safeNavigate(navigate, '/exhibitions', { search: searchTerm.trim() });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch(e);
    }
  };

  const handleFilterClick = (filter: string) => {
    safeNavigate(navigate, '/exhibitions', { filter });
  };

  return (
    <div className="bg-gradient-to-r from-blue-600 to-blue-800 py-20">
      <div className="container mx-auto px-4 text-center">
        <h1 className="mb-6 text-4xl font-bold text-white md:text-6xl">
          Discover Global Exhibitions
        </h1>
        <p className="mb-8 text-xl text-blue-100">
          Find and promote exhibitions, trade shows, and conferences worldwide
        </p>
        
        <div className="mx-auto max-w-2xl">
          <form onSubmit={handleSearch} className="relative">
            <input
              type="text"
              placeholder="Search exhibitions..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleKeyDown}
              className="w-full rounded-lg border-0 px-4 py-3 pr-12 text-gray-900 shadow-lg focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400 hover:text-gray-600"
            >
              <Search className="h-5 w-5" />
            </button>
          </form>
          
          <div className="mt-4 flex flex-wrap justify-center gap-4">
            <Button 
              variant="secondary"
              onClick={() => handleFilterClick('industry')}
            >
              Browse by Industry
            </Button>
            <Button 
              variant="secondary"
              onClick={() => handleFilterClick('location')}
            >
              Browse by Location
            </Button>
            <Button 
              variant="secondary"
              onClick={() => handleFilterClick('upcoming')}
            >
              Upcoming Events
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}