import React from 'react';

export function Terms() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-4xl">
          <h1 className="mb-8 text-4xl font-bold text-gray-900">Terms of Service</h1>
          <div className="prose prose-lg max-w-none space-y-8 rounded-lg bg-white p-8 shadow-sm">
            <p className="text-gray-600">
              Last updated: February 24, 2025
            </p>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">1. Agreement to Terms</h2>
              <p className="text-gray-600">
                By accessing or using Exhibitions.info, you agree to be bound by these Terms of Service 
                and our Privacy Policy. If you disagree with any part of the terms, you may not 
                access our services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">2. User Accounts</h2>
              <ul className="ml-6 list-disc text-gray-600">
                <li>You must be 18 years or older to create an account</li>
                <li>You are responsible for maintaining the security of your account</li>
                <li>You must provide accurate and complete information</li>
                <li>You may not use another person's account</li>
                <li>You must notify us of any security breach or unauthorized use</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">3. Exhibition Listings</h2>
              <h3 className="mt-4 text-xl font-medium text-gray-900">3.1 Listing Requirements</h3>
              <ul className="ml-6 list-disc text-gray-600">
                <li>All information must be accurate and truthful</li>
                <li>You must have the authority to list the exhibition</li>
                <li>Images must be owned by you or properly licensed</li>
                <li>Listings must comply with all applicable laws and regulations</li>
                <li>No inappropriate, offensive, or illegal content</li>
              </ul>

              <h3 className="mt-4 text-xl font-medium text-gray-900">3.2 Prohibited Content</h3>
              <ul className="ml-6 list-disc text-gray-600">
                <li>Fraudulent or misleading information</li>
                <li>Copyrighted material without permission</li>
                <li>Hate speech or discriminatory content</li>
                <li>Malicious code or harmful content</li>
                <li>Spam or duplicate listings</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">4. Fees and Payments</h2>
              <ul className="ml-6 list-disc text-gray-600">
                <li>All fees are listed in your local currency</li>
                <li>Payments are processed securely through our payment providers</li>
                <li>Refunds are subject to our refund policy</li>
                <li>We reserve the right to modify fees with notice</li>
                <li>You are responsible for applicable taxes</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">5. Intellectual Property</h2>
              <p className="text-gray-600">
                The service and its original content (excluding user-submitted content) are and will 
                remain the exclusive property of Exhibitions.info. Our trademarks and trade dress 
                may not be used without our prior written permission.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">6. User Content</h2>
              <p className="text-gray-600">
                By posting content on our platform, you grant us a non-exclusive, worldwide, 
                royalty-free license to use, modify, publicly display, reproduce, and distribute 
                such content on our platform.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">7. Termination</h2>
              <p className="text-gray-600">
                We may terminate or suspend your account and access to our services immediately, 
                without prior notice or liability, for any reason, including breach of these Terms.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">8. Limitation of Liability</h2>
              <p className="text-gray-600">
                In no event shall Exhibitions.info be liable for any indirect, incidental, special, 
                consequential, or punitive damages arising out of or relating to your use of our services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">9. Changes to Terms</h2>
              <p className="text-gray-600">
                We reserve the right to modify or replace these Terms at any time. We will provide 
                notice of any material changes.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">10. Contact Information</h2>
              <p className="text-gray-600">
                Questions about the Terms should be sent to:
              </p>
              <p className="mt-2 text-gray-600">
                Email: legal@exhibitions.info<br />
                Address: 123 Exhibition Street, San Francisco, CA 94105
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}