import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, MapPin, Globe, Building, Share2, ArrowLeft, Clock, Repeat, Users } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { getExhibitionById, getSimilarExhibitions } from '@/lib/api';
import type { Exhibition } from '@/types/exhibition';

export function ExhibitionDetail() {
  const { id } = useParams();
  const [exhibition, setExhibition] = useState<Exhibition | null>(null);
  const [similarExhibitions, setSimilarExhibitions] = useState<Exhibition[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadData() {
      try {
        if (!id) return;
        const [exhibitionData, similarData] = await Promise.all([
          getExhibitionById(id),
          getSimilarExhibitions(id)
        ]);
        setExhibition(exhibitionData);
        setSimilarExhibitions(similarData);
      } catch (err) {
        setError('Failed to load exhibition');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    loadData();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="h-[400px] animate-pulse bg-gray-200" />
        <div className="container mx-auto px-4 py-12">
          <div className="grid gap-8 lg:grid-cols-3">
            <div className="lg:col-span-2">
              <div className="rounded-lg bg-white p-8 shadow-sm">
                <div className="mb-6 h-8 w-2/3 rounded bg-gray-100" />
                <div className="mb-8 space-y-2">
                  {[1, 2, 3].map((i) => (
                    <div key={i} className="h-4 w-full rounded bg-gray-100" />
                  ))}
                </div>
              </div>
            </div>
            <div className="space-y-6">
              <div className="rounded-lg bg-white p-6 shadow-sm">
                <div className="h-40 rounded bg-gray-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error || !exhibition) {
    return (
      <div className="container mx-auto px-4 py-16">
        <div className="text-center">
          <h1 className="mb-4 text-2xl font-bold text-gray-900">Exhibition Not Found</h1>
          <p className="mb-8 text-gray-600">The exhibition you're looking for doesn't exist or has been removed.</p>
          <Link to="/exhibitions">
            <Button>Return to Directory</Button>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div className="relative h-[400px] overflow-hidden">
        <img
          src={exhibition.image_url}
          alt={exhibition.name}
          className="h-full w-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40" />
        <div className="absolute inset-0 flex items-center">
          <div className="container mx-auto px-4">
            <Link
              to="/exhibitions"
              className="mb-4 inline-flex items-center text-white hover:text-blue-100"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back to Directory
            </Link>
            <h1 className="mb-4 text-4xl font-bold text-white md:text-5xl">
              {exhibition.name}
            </h1>
            <div className="flex flex-wrap gap-4">
              <span className="inline-flex items-center rounded-full bg-blue-100 bg-opacity-90 px-3 py-1 text-sm font-medium text-blue-800">
                {exhibition.categories?.name}
              </span>
              <span className={`inline-flex items-center rounded-full px-3 py-1 text-sm font-medium ${
                exhibition.status === 'active'
                  ? 'bg-green-100 text-green-800'
                  : exhibition.status === 'postponed'
                  ? 'bg-yellow-100 text-yellow-800'
                  : 'bg-red-100 text-red-800'
              } bg-opacity-90`}>
                {exhibition.status.charAt(0).toUpperCase() + exhibition.status.slice(1)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="container mx-auto px-4 py-12">
        <div className="grid gap-8 lg:grid-cols-3">
          {/* Left Column - Main Info */}
          <div className="lg:col-span-2">
            <div className="rounded-lg bg-white p-8 shadow-sm">
              <h2 className="mb-6 text-2xl font-bold text-gray-900">About This Exhibition</h2>
              <p className="mb-8 text-gray-600 leading-relaxed">
                {exhibition.description}
              </p>

              <div className="mb-8 grid gap-6 md:grid-cols-2">
                <div className="flex items-start space-x-3">
                  <Calendar className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Date</h3>
                    <p className="text-gray-600">{exhibition.date}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <Clock className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Duration</h3>
                    <p className="text-gray-600">{exhibition.duration}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <Repeat className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Cycle</h3>
                    <p className="text-gray-600">{exhibition.cycle}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <Building className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Venue</h3>
                    <p className="text-gray-600">{exhibition.venue}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <MapPin className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Location</h3>
                    <p className="text-gray-600">{exhibition.location}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <Users className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Organizer</h3>
                    <p className="text-gray-600">{exhibition.organizer}</p>
                  </div>
                </div>

                <div className="flex items-start space-x-3">
                  <Globe className="h-5 w-5 text-blue-600" />
                  <div>
                    <h3 className="font-medium text-gray-900">Region</h3>
                    <p className="text-gray-600">{exhibition.regions?.name}</p>
                  </div>
                </div>
              </div>

              <div className="flex space-x-4">
                <Button size="lg">Register Now</Button>
                <Button variant="outline" size="lg">
                  <Share2 className="mr-2 h-4 w-4" />
                  Share
                </Button>
              </div>
            </div>
          </div>

          {/* Right Column - Additional Info */}
          <div className="space-y-6">
            {/* Contact Card */}
            <div className="rounded-lg bg-white p-6 shadow-sm">
              <h3 className="mb-4 text-lg font-semibold text-gray-900">Contact Organizer</h3>
              <form className="space-y-4">
                <div>
                  <label htmlFor="contact-name" className="block text-sm font-medium text-gray-700">
                    Your Name
                  </label>
                  <input
                    type="text"
                    id="contact-name"
                    name="contact-name"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="contact-email" className="block text-sm font-medium text-gray-700">
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="contact-email"
                    name="contact-email"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <label htmlFor="contact-message" className="block text-sm font-medium text-gray-700">
                    Message
                  </label>
                  <textarea
                    id="contact-message"
                    name="contact-message"
                    rows={4}
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <Button className="w-full">Send Message</Button>
              </form>
            </div>

            {/* Premium Upgrade Card */}
            <div className="rounded-lg bg-gradient-to-br from-blue-500 to-blue-600 p-6 text-white shadow-sm">
              <h3 className="mb-2 text-lg font-semibold">Upgrade to Premium</h3>
              <p className="mb-4 text-blue-100">
                Get priority placement, analytics access, and more features.
              </p>
              <Button variant="secondary" className="w-full">
                Learn More
              </Button>
            </div>
          </div>
        </div>

        {/* Similar Exhibitions */}
        {similarExhibitions.length > 0 && (
          <div className="mt-12">
            <div className="mb-8 text-center">
              <h2 className="text-3xl font-bold text-gray-900">Similar Exhibitions</h2>
              <p className="mt-2 text-gray-600">
                Discover more exhibitions you might be interested in
              </p>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {similarExhibitions.map((similar) => (
                <Link
                  key={similar.id}
                  to={`/exhibition/${similar.id}`}
                  className="group overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm transition-shadow hover:shadow-md"
                >
                  <div className="aspect-video w-full overflow-hidden bg-blue-50">
                    {similar.image_url ? (
                      <img
                        src={similar.image_url}
                        alt={similar.name}
                        className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                      />
                    ) : (
                      <div className="flex h-full w-full items-center justify-center">
                        <span className="text-xl font-bold text-gray-900">{similar.name}</span>
                      </div>
                    )}
                  </div>
                  <div className="p-6">
                    <div className="mb-2 flex items-center justify-between">
                      <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium ${
                        similar.status === 'active'
                          ? 'bg-blue-50 text-blue-700'
                          : similar.status === 'postponed' 
                          ? 'bg-yellow-50 text-yellow-700'
                          : 'bg-red-50 text-red-700'
                      }`}>
                        {similar.status.charAt(0).toUpperCase() + similar.status.slice(1)}
                      </span>
                      <span className="text-sm font-medium text-blue-600">
                        {similar.categories?.name}
                      </span>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-gray-900 line-clamp-2">{similar.name}</h3>
                    <div className="mb-4 space-y-2">
                      <div className="flex items-center text-gray-600">
                        <Calendar className="mr-2 h-4 w-4" />
                        <span>{similar.date}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <Clock className="mr-2 h-4 w-4" />
                        <span>{similar.duration}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <Building className="mr-2 h-4 w-4" />
                        <span>{similar.venue}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}