import { useState } from 'react';

interface MathCaptcha {
  question: string;
  answer: number;
}

export function generateMathCaptcha(): MathCaptcha {
  const operations = ['+', '-', '*'];
  const operation = operations[Math.floor(Math.random() * operations.length)];
  
  let num1: number, num2: number;
  
  switch (operation) {
    case '+':
      num1 = Math.floor(Math.random() * 20) + 1;
      num2 = Math.floor(Math.random() * 20) + 1;
      break;
    case '-':
      num1 = Math.floor(Math.random() * 20) + 1;
      num2 = Math.floor(Math.random() * num1) + 1; // Ensure positive result
      break;
    case '*':
      num1 = Math.floor(Math.random() * 10) + 1;
      num2 = Math.floor(Math.random() * 5) + 1;
      break;
    default:
      num1 = Math.floor(Math.random() * 20) + 1;
      num2 = Math.floor(Math.random() * 20) + 1;
  }

  const answer = operation === '+' ? num1 + num2 
               : operation === '-' ? num1 - num2 
               : num1 * num2;

  return {
    question: `What is ${num1} ${operation} ${num2}?`,
    answer
  };
}

export function useMathCaptcha() {
  const [captcha, setCaptcha] = useState(generateMathCaptcha());
  
  const refreshCaptcha = () => {
    setCaptcha(generateMathCaptcha());
  };
  
  return { captcha, refreshCaptcha };
}