import { NavigateFunction } from 'react-router-dom';

export interface NavigationOptions {
  replace?: boolean;
  state?: any;
}

export function safeNavigate(
  navigate: NavigateFunction,
  to: string,
  params?: Record<string, string>,
  options: NavigationOptions = {}
) {
  try {
    const searchParams = new URLSearchParams();
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value) searchParams.set(key, value);
      });
    }
    
    const search = searchParams.toString();
    const path = search ? `${to}?${search}` : to;
    
    navigate(path, options);
    return true;
  } catch (error) {
    console.error('Navigation error:', error);
    return false;
  }
}

export function createSearchParams(params: Record<string, string>): URLSearchParams {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (value) searchParams.set(key, value);
  });
  return searchParams;
}