import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, FileText, XCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/auth-context';
import { supabase } from '@/lib/supabase';
import Papa from 'papaparse';
import type { Exhibition } from '@/types/exhibition';
import { StatsCards } from './components/stats-cards';
import { ListingsTable } from './components/listings-table';
import { UsersTable } from './components/users-table';
import { AuditLogList } from './components/audit-log';
import type { AdminUser, AuditLog, ManualListingForm } from './types';

export function AdminDashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);
  const [listings, setListings] = useState<Exhibition[]>([]);
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [showManualForm, setShowManualForm] = useState(false);
  const [manualForm, setManualForm] = useState<ManualListingForm>({
    name: '',
    description: '',
    date: new Date().toISOString().split('T')[0],
    duration: '1 day',
    cycle: 'One-time',
    venue: '',
    location: '',
    category_id: '',
    region_id: '',
    organizer: '',
    image_url: ''
  });
  const [categories, setCategories] = useState<Array<{ id: string; name: string }>>([]);
  const [regions, setRegions] = useState<Array<{ id: string; name: string }>>([]);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [showUploadGuide, setShowUploadGuide] = useState(false);

  const CSV_TEMPLATE = {
    headers: ['name', 'description', 'date', 'start_date', 'end_date', 'location', 'venue', 'category_id', 'region_id', 'organizer', 'image_url'],
    example: {
      name: 'Tech Conference 2024',
      description: 'Annual technology conference',
      date: '2024-06-15',
      start_date: '2024-06-15',
      end_date: '2024-06-17',
      location: 'San Francisco, CA',
      venue: 'Moscone Center',
      category_id: '(category UUID)',
      region_id: '(region UUID)',
      organizer: 'Tech Events Inc',
      image_url: 'https://example.com/image.jpg'
    }
  };

  useEffect(() => {
    checkAdminAccess();
  }, [user, navigate]);

  const checkAdminAccess = async () => {
    try {
      const { data, error } = await supabase.rpc('is_admin');
      if (error) throw error;
      
      setIsAdmin(data);
      if (!data) {
        navigate('/');
        return;
      }

      loadDashboardData();
    } catch (err) {
      console.error('Error checking admin access:', err);
      navigate('/');
    }
  };

  const loadDashboardData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const [listingsData, usersData, { data: logsData, error: logsError }, categoriesData, regionsData] = await Promise.all([
        supabase
          .from('exhibitions')
          .select(`
            *,
            categories (name),
            regions (name)
          `)
          .order('created_at', { ascending: false }),
        supabase
          .from('users')
          .select('id, email, is_admin, created_at')
          .order('created_at', { ascending: false }),
        supabase
          .from('admin_audit_logs')
          .select('*')
          .order('created_at', { ascending: false }),
        supabase
          .from('categories')
          .select('*')
          .order('name'),
        supabase
          .from('regions')
          .select('*')
          .order('name')
      ]);

      if (listingsData.error) throw listingsData.error;
      if (usersData.error) throw usersData.error;
      if (logsError) throw logsError;
      if (categoriesData.error) throw categoriesData.error;
      if (regionsData.error) throw regionsData.error;

      setListings(listingsData.data || []);
      setUsers(usersData.data || []);
      setAuditLogs(logsData || []);
      setCategories(categoriesData.data || []);
      setRegions(regionsData.data || []);
    } catch (err) {
      console.error('Error loading dashboard data:', err);
      setError('Failed to load dashboard data');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    // File validation constants
    const FILE_CONSTRAINTS = {
      maxSize: 10 * 1024 * 1024, // 10MB
      allowedTypes: ['text/csv'],
      requiredFields: ['name', 'date', 'location', 'category_id', 'region_id']
    };

    try {
      setUploadStatus('Processing file...');
      setError(null);

      // Validate file metadata
      if (file.size > FILE_CONSTRAINTS.maxSize) {
        throw new Error(`File size must be less than ${FILE_CONSTRAINTS.maxSize / (1024 * 1024)}MB`);
      }

      if (!FILE_CONSTRAINTS.allowedTypes.includes(file.type)) {
        throw new Error('Only CSV files are allowed');
      }

      // Read and parse file
      const text = await file.text();
      const result = Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (header) => header?.trim().toLowerCase() || '',
        error: (error) => {
          throw new Error(`CSV parsing error: ${error.message}`);
        }
      });

      if (result.errors.length > 0) {
        const errorMessages = result.errors.map(err => err.message).join('; ');
        throw new Error(`CSV parsing errors: ${errorMessages}`);
      }

      const data = result.data as Record<string, any>[];

      if (data.length === 0) {
        throw new Error('CSV file contains no valid data rows');
      }

      // Get region mappings
      const { data: regionsData, error: regionsError } = await supabase
        .from('regions')
        .select('id, name');
      
      if (regionsError) throw regionsError;
      
      const regionMap = new Map(regionsData.map(r => [
        r.name.toUpperCase(),
        r.id
      ]));

      // Get category ID for "Trade Shows & Exhibitions"
      const { data: categoriesData, error: categoriesError } = await supabase
        .rpc('get_category_by_name', {
          p_name: 'Trade Shows & Exhibitions'
        });
      
      if (categoriesError) throw categoriesError;
      if (!categoriesData) throw new Error('Failed to get category ID');

      // Process and validate data
      const processedData = await Promise.all(data.map(async (row, index) => {
        // Check required fields
        const name = row.name?.trim();
        const location = row.venue?.split('\n')[0]?.trim() || row.location?.trim();
        const region = row.region?.trim().toUpperCase();
        const regionId = regionMap.get(region);
        const categoryId = categoriesData;

        const missingFields = [];
        if (!name) missingFields.push('name');
        if (!location) missingFields.push('location');
        if (!regionId) missingFields.push('region_id');
        if (!categoryId) missingFields.push('category_id');

        if (missingFields.length > 0) {
          throw new Error(`Row ${index + 1}: Missing required fields: ${missingFields.join(', ')}`);
        }

        // Validate and parse dates
        let startDate: Date, endDate: Date;
        try {
          // Parse date like "Feb. 2025" or "March 2025"
          const dateStr = row.date?.trim().replace('.', '');
          startDate = new Date(dateStr);
          if (isNaN(startDate.getTime())) {
            throw new Error('Invalid start date');
          }

          // Set end date to last day of the month
          endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
          if (isNaN(endDate.getTime())) {
            throw new Error('Invalid end date');
          }

          if (endDate < startDate) {
            throw new Error('End date must be after start date');
          }
        } catch (dateError) {
          throw new Error(`Row ${index + 1}: ${dateError instanceof Error ? dateError.message : 'Date parsing error'}`);
        }

        // Calculate duration
        const durationDays = Math.max(1, Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)));

        return {
          name,
          description: row.description?.trim() || '',
          date: startDate.toISOString().split('T')[0],
          start_date: startDate.toISOString(),
          end_date: endDate.toISOString(),
          duration: row.duration?.trim() || `${durationDays} day${durationDays > 1 ? 's' : ''}`,
          cycle: row.cycle?.trim()?.toLowerCase()?.replace('once a year', 'Annual')
                                               ?.replace('twice a year', 'Bi-annual') || 'Annual',
          venue: row.venue?.split('\n').pop()?.trim() || '',
          location,
          category_id: categoryId,
          region_id: regionId,
          organizer: row.organizer?.trim() || '',
          image_url: null,
          status: 'active'
        };
      }));

      // Insert data in batches
      const batchSize = 50;
      let importedCount = 0;

      for (let i = 0; i < processedData.length; i += batchSize) {
        const batch = processedData.slice(i, i + batchSize);
        const { error: insertError } = await supabase
          .from('exhibitions')
          .insert(batch);
        
        if (insertError) {
          console.error('Batch insert error:', {
            batchIndex: i,
            error: insertError
          });
          throw new Error(`Database error: ${insertError.message}`);
        }

        importedCount += batch.length;
        setUploadStatus(`Imported ${importedCount} of ${processedData.length} exhibitions...`);
      }

      // Log success metrics
      console.info('Import completed successfully:', {
        totalRows: data.length,
        processedRows: processedData.length,
        importedRows: importedCount
      });

      setUploadStatus(`Successfully imported ${importedCount} exhibitions`);
      await loadDashboardData();

    } catch (err) {
      // Enhanced error handling
      console.error('File processing error:', {
        error: err,
        errorType: err instanceof Error ? err.constructor.name : typeof err,
        errorMessage: err instanceof Error ? err.message : String(err)
      });

      const errorMessage = err instanceof Error ? err.message : 'Failed to process file';
      setError(errorMessage);
      setUploadStatus('Upload failed. Please check the file format and try again.');
    }
  };

  const handleRemoveListing = async (id: string) => {
    try {
      const { error } = await supabase
        .from('exhibitions')
        .delete()
        .eq('id', id);

      if (error) throw error;

      await supabase.rpc('log_admin_action', {
        action: 'remove_listing',
        entity_type: 'exhibition',
        entity_id: id,
        details: { reason: 'inappropriate content' }
      });

      setListings(listings.filter(listing => listing.id !== id));
    } catch (err) {
      console.error('Error removing listing:', err);
      setError('Failed to remove listing');
    }
  };

  const handleDisableUser = async (userId: string) => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ is_banned: true })
        .eq('id', userId);

      if (error) throw error;

      await supabase.rpc('log_admin_action', {
        action: 'disable_user',
        entity_type: 'user',
        entity_id: userId
      });

      setUsers(users.map(user => 
        user.id === userId 
          ? { ...user, is_banned: true }
          : user
      ));
    } catch (err) {
      console.error('Error disabling user:', err);
      setError('Failed to disable user');
    }
  };

  const handleManualSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setError(null);
      
      if (!manualForm.name || !manualForm.date || !manualForm.location || !manualForm.category_id || !manualForm.region_id) {
        setError('Please fill in all required fields');
        return;
      }

      const { error } = await supabase
        .from('exhibitions')
        .insert([{
          ...manualForm,
          status: 'active'
        }]);

      if (error) throw error;

      setShowManualForm(false);
      loadDashboardData();
    } catch (err) {
      console.error('Error creating listing:', err);
      setError('Failed to create listing');
    }
  };

  if (!isAdmin) {
    return null;
  }

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="container mx-auto px-4">
          <div className="animate-pulse space-y-8">
            <div className="h-8 w-1/4 rounded bg-gray-200" />
            <div className="grid gap-6 md:grid-cols-3">
              {[1, 2, 3].map(i => (
                <div key={i} className="h-32 rounded-lg bg-gray-200" />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Admin Dashboard</h1>
          <p className="mt-2 text-gray-600">
            Manage exhibitions, users, and monitor platform activity
          </p>
        </div>

        {error && (
          <div className="mb-6 rounded-lg bg-red-50 p-4 text-red-800">
            {error}
          </div>
        )}

        <StatsCards
          listings={listings}
          users={users}
          auditLogsCount={auditLogs.length}
        />

        <div className="mb-8">
          <h2 className="mb-4 text-xl font-semibold text-gray-900">Add Listings</h2>
          <div className="grid gap-4 md:grid-cols-3">
            <Button
              onClick={() => setShowManualForm(true)}
              className="flex items-center justify-center"
            >
              <Plus className="mr-2 h-5 w-5" />
              Manual Entry
            </Button>
            <label className="cursor-pointer">
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                className="hidden"
              />
              <div className="flex h-full items-center justify-center rounded-md border-2 border-dashed border-gray-300 px-4 py-2 hover:border-blue-500">
                <FileText className="mr-2 h-5 w-5" />
                Upload CSV
                <button
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowUploadGuide(true);
                  }}
                  className="ml-2 text-sm text-blue-600 hover:text-blue-700"
                >
                  View Guide
                </button>
              </div>
            </label>
          </div>
          
          {uploadStatus && (
            <p className="mt-2 text-sm text-gray-600">{uploadStatus}</p>
          )}
        </div>

        <ListingsTable
          listings={listings}
          searchTerm={searchTerm}
          filter={filter}
          onSearchChange={setSearchTerm}
          onFilterChange={setFilter}
          onRemove={handleRemoveListing}
        />

        <UsersTable
          users={users}
          onDisableUser={handleDisableUser}
        />

        <AuditLogList logs={auditLogs} />

        {/* Manual Form Modal */}
        {showManualForm && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 p-4">
            <div className="w-full max-w-4xl rounded-lg bg-white p-6">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">
                  Add New Exhibition
                </h3>
                <button
                  onClick={() => setShowManualForm(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <form onSubmit={handleManualSubmit} className="space-y-6">
                {/* Form fields */}
                <div className="flex justify-end space-x-3">
                  <Button onClick={() => setShowManualForm(false)} variant="outline">
                    Cancel
                  </Button>
                  <Button type="submit">
                    Create Exhibition
                  </Button>
                </div>
              </form>
            </div>
          </div>
        )}

        {/* CSV Upload Guide Modal */}
        {showUploadGuide && (
          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50 p-4">
            <div className="w-full max-w-2xl rounded-lg bg-white p-6">
              <div className="mb-4 flex items-center justify-between">
                <h3 className="text-lg font-semibold text-gray-900">
                  CSV Upload Guide
                </h3>
                <button
                  onClick={() => setShowUploadGuide(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <XCircle className="h-6 w-6" />
                </button>
              </div>
              
              <div className="space-y-4">
                <div>
                  <h4 className="font-medium text-gray-900">Required Fields</h4>
                  <ul className="mt-2 list-inside list-disc text-sm text-gray-600">
                    <li>name - Exhibition title</li>
                    <li>date - Exhibition date (YYYY-MM-DD)</li>
                    <li>location - City, Country</li>
                    <li>category_id - UUID from categories table</li>
                    <li>region_id - UUID from regions table</li>
                  </ul>
                </div>

                <div>
                  <h4 className="font-medium text-gray-900">Optional Fields</h4>
                  <ul className="mt-2 list-inside list-disc text-sm text-gray-600">
                    <li>description - Exhibition details</li>
                    <li>start_date - Start date (YYYY-MM-DD)</li>
                    <li>end_date - End date (YYYY-MM-DD)</li>
                    <li>venue - Venue name</li>
                    <li>organizer - Organizer name</li>
                    <li>image_url - Image URL</li>
                  </ul>
                </div>

                <div>
                  <h4 className="font-medium text-gray-900">Available Categories</h4>
                  <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                    {categories.map(category => (
                      <div key={category.id} className="flex items-center space-x-2">
                        <span className="font-mono text-gray-500">{category.id}</span>
                        <span className="text-gray-600">- {category.name}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <h4 className="font-medium text-gray-900">Available Regions</h4>
                  <div className="mt-2 grid grid-cols-2 gap-2 text-sm">
                    {regions.map(region => (
                      <div key={region.id} className="flex items-center space-x-2">
                        <span className="font-mono text-gray-500">{region.id}</span>
                        <span className="text-gray-600">- {region.name}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="rounded-lg bg-gray-50 p-4">
                  <h4 className="font-medium text-gray-900">Example CSV Format</h4>
                  <pre className="mt-2 overflow-x-auto text-sm text-gray-600">
                    {CSV_TEMPLATE.headers.join(',')}
                    {'\n'}
                    {Object.values(CSV_TEMPLATE.example).join(',')}
                  </pre>
                </div>
                {/* Guide content */}
                <div className="mt-6 flex justify-end">
                  <Button onClick={() => setShowUploadGuide(false)}>
                    Close Guide
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}