import { isValidUrl } from '@/lib/utils';

export class URLBuilder {
  private baseUrl: string;
  private readonly defaultBase: string;

  constructor(baseUrl: string = window.location.origin) {
    this.baseUrl = baseUrl;
    this.defaultBase = baseUrl;
  }

  private sanitizePath(path: string): string {
    // Ensure path starts with / and has no duplicate slashes
    return '/' + path.trim()
      .replace(/^\/+/, '')
      .replace(/\/+/g, '/')
      .replace(/\/$/, '');
  }

  public build(path: string, params?: Record<string, string>): string {
    try {
      const safePath = this.sanitizePath(path);
      // Handle both absolute and relative paths
      const url = safePath.startsWith('http') 
        ? new URL(safePath)
        : new URL(safePath, window.location.origin);
      
      if (params) {
        Object.entries(params)
          .filter(([_, value]) => value !== undefined && value !== null && value !== '')
          .forEach(([key, value]) => {
            if (value !== undefined && value !== null && value !== '') {
              url.searchParams.set(key, encodeURIComponent(value));
            }
          });
      }
      
      return `${url.pathname}${url.search}`;
    } catch {
      const safePath = this.sanitizePath(path);
      const search = params ? 
        `?${Object.entries(params)
            .filter(([_, value]) => value !== undefined && value !== null && value !== '')
            .map(([key, value]) => {
              return `${key}=${encodeURIComponent(value)}`;
            })
            .join('&')}`
        : '';
      return `${safePath}${search}`;
    }
  }

  public isValidPath(path: string): boolean {
    try {
      const cleanPath = path.startsWith('/') ? path : `/${path}`;
      new URL(cleanPath, window.location.origin);
      return true;
    } catch {
      return false;
    }
  }

  public getSearchParams(search: string): URLSearchParams {
    try {
      const cleanSearch = search.startsWith('?') ? search.slice(1) : search;
      return new URLSearchParams(cleanSearch);
    } catch {
      return new URLSearchParams();
    }
  }
}

// Create a singleton instance
export const urlBuilder = new URLBuilder();