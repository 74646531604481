export interface Exhibition {
  id: string;
  name: string;
  description: string;
  image_url: string;
  date: string;
  duration: string;
  cycle: string;
  venue: string;
  location: string;
  category_id: string;
  region_id: string;
  organizer: string;
  status: string;
  created_at: string;
  updated_at: string;
  created_by?: string;
  categories?: {
    name: string;
  };
  regions?: {
    name: string;
  };
}

export interface Category {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface Region {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export interface ExhibitionStatistics {
  total_count: number;
  by_region: {
    region_name: string;
    count: number;
  }[];
  by_category: {
    category_name: string;
    count: number;
  }[];
  active_count: number;
}

export interface ImageRequirements {
  formats: string[];
  maxSize: number; // in MB
  minWidth: number;
  minHeight: number;
  aspectRatio: string;
}

export interface Subscription {
  id: string;
  plan_type: string;
  status: string;
  current_period_start: string;
  current_period_end: string;
  cancel_at_period_end: boolean;
}

export interface SubscriptionUsage {
  listings_used: number;
  listings_limit: number;
}

export interface PaymentHistory {
  id: string;
  amount: number;
  currency: string;
  status: string;
  payment_method: string;
  created_at: string;
}

export interface SubscriptionDetails {
  subscription: Subscription;
  usage: SubscriptionUsage;
  payments: PaymentHistory[];
}

export interface Invoice {
  id: string;
  invoice_number: string;
  amount: number;
  currency: string;
  status: string;
  issued_date: string;
  due_date: string;
  paid_date: string;
  pdf_url: string;
}

export const LISTING_REQUIREMENTS = {
  name: {
    minLength: 10,
    maxLength: 100,
  },
  description: {
    minLength: 100,
    maxLength: 2000,
  },
  image: {
    optional: true,
    formats: ['jpg', 'jpeg', 'png', 'webp'],
    maxSize: 5, // 5MB
    minWidth: 1200,
    minHeight: 675,
    aspectRatio: '16:9',
  } as ImageRequirements,
  terms: [
    'All information provided must be accurate and truthful.',
    'Images must be owned by you or licensed for your use.',
    'No inappropriate, offensive, or illegal content.',
    'No spam or duplicate listings.',
    'Contact information must be valid and current.',
    'Pricing information must be accurate and transparent.',
    'You must have the authority to list this exhibition.',
    'Changes to listings must be updated promptly.',
    'Cancellations must be communicated immediately.',
    'Compliance with local event regulations is required.'
  ]
};