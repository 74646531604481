import React, { useState } from 'react';
import { Check } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '@/contexts/auth-context';
import { urlBuilder } from '@/lib/url';

const plans = [
  {
    name: 'Basic Free',
    price: 'Free',
    description: 'Perfect for getting started',
    id: 'free',
    features: [
      'List 1 exhibition',
      'Basic analytics',
      'Standard support',
      'Directory listing',
      'Email notifications'
    ]
  },
  {
    name: 'Professional',
    price: '$29',
    period: 'per month',
    id: 'professional',
    description: 'Best for growing organizations',
    features: [
      'List up to 10 exhibitions',
      'Advanced analytics',
      'Priority support',
      'Featured listings',
      'Email & SMS notifications'
    ],
    popular: true
  },
  {
    name: 'Enterprise',
    description: 'For large organizations',
    id: 'enterprise',
    price: 'Contact Us',
    features: []
  }
];

export function Pricing() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handlePlanSelect = async (planId: string, isYearly: boolean = false) => {
    try {
      setLoading(planId);
      setError(null);

      if (!user) {
        const redirectPath = urlBuilder.build('/signin', { next: '/pricing' });
        navigate(redirectPath);
        return;
      }

      switch (planId) {
        case 'free':
          navigate('/list-exhibition');
          break;
        case 'professional':
          navigate('/contact', { 
            state: { type: 'professional', plan: isYearly ? 'yearly' : 'monthly' }
          });
          break;
        case 'enterprise':
          navigate('/contact', { 
            state: { type: 'enterprise' }
          });
          break;
      }
    } catch (err) {
      const message = err instanceof Error ? err.message : 'Failed to process request';
      console.error('Plan selection error:', message);
      setError(message);
    } finally {
      setLoading(null);
    }
  };

  return (
    <div className="bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="text-center">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Simple, transparent pricing</h1>
          <p className="text-xl text-gray-600 mb-12">
            Choose the perfect plan for your exhibition needs
          </p>
        </div>

        {error && (
          <div className="mx-auto mb-8 max-w-md rounded-lg bg-red-50 p-4 text-center text-red-800">
            {error}
          </div>
        )}

        <div className="grid gap-8 md:grid-cols-3">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`relative rounded-2xl bg-white p-8 shadow-lg ${
                plan.popular ? 'ring-2 ring-blue-500' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                  <span className="bg-blue-500 text-white px-3 py-1 rounded-full text-sm font-medium">
                    Most Popular
                  </span>
                </div>
              )}

              <div className="text-center">
                <h3 className="text-xl font-semibold text-gray-900">{plan.name}</h3>
                <div className="mt-4 flex items-baseline justify-center">
                  <span className="text-4xl font-bold tracking-tight text-gray-900">
                    {plan.price}
                  </span>
                  {plan.period && (
                    <span className="text-gray-500 ml-1">{plan.period}</span>
                  )}
                </div>
                <p className="mt-2 text-sm text-gray-500">{plan.description}</p>
              </div>

              {plan.features.length > 0 ? (
                <ul className="mt-8 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-3 flex-shrink-0" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="mt-8 text-center text-gray-600">
                  <p>Custom solutions for large organizations</p>
                  <p className="mt-2">Unlimited exhibitions and enterprise features</p>
                </div>
              )}

              <div className="mt-8">
                <Button
                  variant={plan.popular ? 'primary' : 'outline'}
                  className="w-full"
                  onClick={() => handlePlanSelect(plan.id)}
                  disabled={loading === plan.id}
                >
                  {loading === plan.id ? 'Processing...' : 'Get started'}
                </Button>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <h2 className="text-2xl font-bold text-gray-900 mb-4">
            Need a custom solution?
          </h2>
          <p className="text-gray-600 mb-8">
            Contact our sales team for a tailored package that meets your specific needs
          </p>
          <Link to="/contact">
            <Button variant="outline" size="lg">
              Contact Sales
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}