import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getAuthRedirectUrl(path: string, searchParams?: URLSearchParams): string {
  try {
    // Normalize the path
    const cleanPath = '/' + (path || '')
      .replace(/^\/+/, '')
      .replace(/\/+/g, '/')
      .replace(/\/$/, '');
    
    // Handle search params
    let search = '';
    if (searchParams?.toString()) {
      search = `?${searchParams.toString()}`;
    }
    
    return cleanPath + search;
  } catch {
    // Safe fallback that always returns a valid path
    return '/';
  }
}

export function isValidUrl(url: string): boolean {
  try {
    if (!url) return false;
    // Use window.location.origin as base for relative URLs
    new URL(url, window.location.origin);
    return true;
  } catch {
    return false;
  }
}