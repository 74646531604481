import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  },
  global: {
    headers: {
      'X-Client-Info': 'exhibitions-info'
    }
  }
});

// Verify connection
supabase.from('exhibitions').select('count').limit(1).single()
  .then(() => {
    console.log('Successfully connected to Supabase');
  })
  .catch(error => {
    console.error('Error connecting to Supabase:', error);
    if (!supabaseUrl || !supabaseAnonKey) {
      console.error('Missing Supabase environment variables. Please connect to Supabase using the "Connect to Supabase" button.');
    }
  });