import React from 'react';

export function Privacy() {
  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="container mx-auto px-4">
        <div className="mx-auto max-w-4xl">
          <h1 className="mb-8 text-4xl font-bold text-gray-900">Privacy Policy</h1>
          <div className="prose prose-lg max-w-none space-y-8 rounded-lg bg-white p-8 shadow-sm">
            <p className="text-gray-600">
              Last updated: February 24, 2025
            </p>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">1. Introduction</h2>
              <p className="text-gray-600">
                Exhibitions.info ("we," "our," or "us") is committed to protecting your privacy. 
                This Privacy Policy explains how we collect, use, disclose, and safeguard your information 
                when you visit our website and use our services.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">2. Information We Collect</h2>
              <h3 className="mt-4 text-xl font-medium text-gray-900">2.1 Personal Information</h3>
              <ul className="ml-6 list-disc text-gray-600">
                <li>Email address</li>
                <li>Name</li>
                <li>Contact information</li>
                <li>Organization details</li>
                <li>Payment information (processed securely by our payment providers)</li>
              </ul>

              <h3 className="mt-4 text-xl font-medium text-gray-900">2.2 Usage Information</h3>
              <ul className="ml-6 list-disc text-gray-600">
                <li>Log data</li>
                <li>Device information</li>
                <li>Location data</li>
                <li>Cookie data</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">3. How We Use Your Information</h2>
              <p className="text-gray-600">We use the collected information for:</p>
              <ul className="ml-6 list-disc text-gray-600">
                <li>Providing and maintaining our services</li>
                <li>Processing your transactions</li>
                <li>Sending you service-related communications</li>
                <li>Improving our website and services</li>
                <li>Analyzing usage patterns</li>
                <li>Preventing fraud and abuse</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">4. Information Sharing</h2>
              <p className="text-gray-600">
                We may share your information with:
              </p>
              <ul className="ml-6 list-disc text-gray-600">
                <li>Service providers and business partners</li>
                <li>Legal authorities when required by law</li>
                <li>Other users (only information you choose to make public)</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">5. Data Security</h2>
              <p className="text-gray-600">
                We implement appropriate technical and organizational measures to protect your 
                personal information. However, no method of transmission over the Internet is 
                100% secure, and we cannot guarantee absolute security.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">6. Your Rights</h2>
              <p className="text-gray-600">You have the right to:</p>
              <ul className="ml-6 list-disc text-gray-600">
                <li>Access your personal information</li>
                <li>Correct inaccurate data</li>
                <li>Request deletion of your data</li>
                <li>Object to processing of your data</li>
                <li>Withdraw consent</li>
              </ul>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">7. Cookies</h2>
              <p className="text-gray-600">
                We use cookies and similar tracking technologies to enhance your experience. 
                You can control cookie settings through your browser preferences.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">8. Children's Privacy</h2>
              <p className="text-gray-600">
                Our services are not intended for children under 13. We do not knowingly collect 
                personal information from children under 13.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">9. Changes to This Policy</h2>
              <p className="text-gray-600">
                We may update this Privacy Policy periodically. We will notify you of any material 
                changes by posting the new policy on this page.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900">10. Contact Us</h2>
              <p className="text-gray-600">
                If you have questions about this Privacy Policy, please contact us at:
              </p>
              <p className="mt-2 text-gray-600">
                Email: privacy@exhibitions.info<br />
                Address: 123 Exhibition Street, San Francisco, CA 94105
              </p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}