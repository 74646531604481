import React from 'react';
import { Link } from 'react-router-dom';
import { Globe } from 'lucide-react';

export function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200 mt-auto">
      <div className="container mx-auto px-4 py-12">
        <div className="grid gap-8 md:grid-cols-4">
          <div>
            <Link to="/" className="flex items-center">
              <div className="flex h-8 w-8 items-center justify-center rounded bg-blue-600 text-white">
                <Globe className="h-5 w-5" />
              </div>
              <div className="ml-2 flex flex-col">
                <span className="text-xl font-bold text-gray-900">
                  Exhibitions.info
                </span>
                <span className="text-sm text-gray-600">
                  Connecting Global Events
                </span>
              </div>
            </Link>
            <p className="mt-4 text-gray-600">
              The global platform for discovering and promoting exhibitions worldwide.
            </p>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900">Directory</h3>
            <ul className="mt-4 space-y-2">
              <li>
                <Link to="/exhibitions" className="text-gray-600 hover:text-gray-900">
                  Browse Exhibitions
                </Link>
              </li>
              <li>
                <Link to="/list-exhibition" className="text-gray-600 hover:text-gray-900">
                  List Your Exhibition
                </Link>
              </li>
              <li>
                <Link to="/pricing" className="text-gray-600 hover:text-gray-900">
                  Pricing
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900">Company</h3>
            <ul className="mt-4 space-y-2">
              <li>
                <Link to="/about" className="text-gray-600 hover:text-gray-900">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-gray-900">
                  Contact
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-600 hover:text-gray-900">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-600 hover:text-gray-900">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="font-semibold text-gray-900">Connect</h3>
            <ul className="mt-4 space-y-2">
              <li>
                <a href="https://twitter.com/exhibitionsinfo" className="text-gray-600 hover:text-gray-900">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://linkedin.com/company/exhibitionsinfo" className="text-gray-600 hover:text-gray-900">
                  LinkedIn
                </a>
              </li>
              <li>
                <a href="https://facebook.com/exhibitionsinfo" className="text-gray-600 hover:text-gray-900">
                  Facebook
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-200 pt-8">
          <p className="text-center text-gray-600">
            © {new Date().getFullYear()} Exhibitions.info. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}