import React from 'react';
import { XCircle, Ban, CheckCircle } from 'lucide-react';
import type { AuditLog } from '../types';

interface AuditLogProps {
  logs: AuditLog[];
}

export function AuditLogList({ logs }: AuditLogProps) {
  return (
    <div>
      <h2 className="mb-4 text-xl font-semibold text-gray-900">Audit Log</h2>
      <div className="rounded-lg border border-gray-200 bg-white p-6">
        <div className="space-y-4">
          {logs.map((log) => (
            <div key={log.id} className="flex items-start border-b border-gray-100 pb-4">
              <div className="mr-4">
                {log.action.includes('remove') ? (
                  <XCircle className="h-6 w-6 text-red-500" />
                ) : log.action.includes('disable') ? (
                  <Ban className="h-6 w-6 text-orange-500" />
                ) : (
                  <CheckCircle className="h-6 w-6 text-green-500" />
                )}
              </div>
              <div>
                <p className="text-sm text-gray-900">
                  <span className="font-medium">{log.action}</span> on {log.entity_type}
                </p>
                <p className="text-xs text-gray-500">
                  {new Date(log.created_at).toLocaleString()}
                </p>
                {log.details && (
                  <pre className="mt-1 text-xs text-gray-600">
                    {JSON.stringify(log.details, null, 2)}
                  </pre>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}