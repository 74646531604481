import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Calendar, MapPin, Building, Edit, Trash2, AlertCircle, CheckCircle, XCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/auth-context';
import { supabase } from '@/lib/supabase';
import type { Exhibition } from '@/types/exhibition';

export function ProfilePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [listings, setListings] = useState<Exhibition[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate('/signin?next=/profile');
      return;
    }

    loadUserListings();
  }, [user, navigate]);

  const loadUserListings = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('exhibitions')
        .select(`
          *,
          categories (name),
          regions (name)
        `)
        .eq('created_by', user?.id)
        .order('created_at', { ascending: false });

      if (fetchError) throw fetchError;
      setListings(data || []);
    } catch (err) {
      console.error('Error loading listings:', err);
      setError('Failed to load your listings');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const { error: deleteError } = await supabase
        .from('exhibitions')
        .delete()
        .eq('id', id)
        .eq('created_by', user?.id);

      if (deleteError) throw deleteError;
      
      setListings(listings.filter(listing => listing.id !== id));
      setShowDeleteConfirm(false);
      setDeleteId(null);
    } catch (err) {
      console.error('Error deleting listing:', err);
      setError('Failed to delete listing');
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'active':
        return <CheckCircle className="h-5 w-5 text-green-500" />;
      case 'postponed':
        return <AlertCircle className="h-5 w-5 text-yellow-500" />;
      case 'cancelled':
        return <XCircle className="h-5 w-5 text-red-500" />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="container mx-auto px-4">
          <div className="mb-8 animate-pulse">
            <div className="h-8 w-1/3 rounded bg-gray-200" />
            <div className="mt-2 h-4 w-1/2 rounded bg-gray-200" />
          </div>
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="rounded-lg bg-white p-6 shadow-sm">
                <div className="mb-4 h-48 rounded bg-gray-100" />
                <div className="space-y-2">
                  <div className="h-6 w-3/4 rounded bg-gray-100" />
                  <div className="h-4 w-1/2 rounded bg-gray-100" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">My Profile</h1>
          <p className="mt-2 text-gray-600">Manage your exhibition listings and account settings</p>
        </div>

        <div className="mb-8 rounded-lg bg-white p-6 shadow-sm">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Account Information</h2>
              <p className="mt-1 text-gray-600">{user?.email}</p>
            </div>
            <Button variant="outline">Edit Profile</Button>
          </div>
        </div>

        <div className="mb-8">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">My Listings</h2>
            <Link to="/list-exhibition">
              <Button>Add New Exhibition</Button>
            </Link>
          </div>

          {error && (
            <div className="mt-4 rounded-lg bg-red-50 p-4 text-red-800">
              {error}
            </div>
          )}

          {listings.length === 0 ? (
            <div className="mt-4 rounded-lg bg-gray-50 p-8 text-center">
              <p className="text-gray-600">You haven't created any listings yet.</p>
              <Link to="/list-exhibition" className="mt-4 inline-block text-blue-600 hover:text-blue-500">
                Create your first listing
              </Link>
            </div>
          ) : (
            <div className="mt-4 grid gap-6 md:grid-cols-2 lg:grid-cols-3">
              {listings.map((listing) => (
                <div
                  key={listing.id}
                  className="overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm"
                >
                  <div className="aspect-video overflow-hidden">
                    <img
                      src={listing.image_url}
                      alt={listing.name}
                      className="h-full w-full object-cover"
                    />
                  </div>
                  <div className="p-6">
                    <div className="mb-2 flex items-center justify-between">
                      <span className={`inline-flex items-center gap-1 rounded-full px-2.5 py-0.5 text-sm font-medium ${
                        listing.status === 'active'
                          ? 'bg-green-100 text-green-800'
                          : listing.status === 'postponed'
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-red-100 text-red-800'
                      }`}>
                        {getStatusIcon(listing.status)}
                        {listing.status.charAt(0).toUpperCase() + listing.status.slice(1)}
                      </span>
                      <span className="text-sm font-medium text-blue-600">
                        {listing.categories?.name}
                      </span>
                    </div>
                    <h3 className="mb-2 text-xl font-bold text-gray-900">{listing.name}</h3>
                    <div className="mb-4 space-y-2">
                      <div className="flex items-center text-gray-600">
                        <Calendar className="mr-2 h-4 w-4" />
                        <span>{listing.date}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <Building className="mr-2 h-4 w-4" />
                        <span>{listing.venue}</span>
                      </div>
                      <div className="flex items-center text-gray-600">
                        <MapPin className="mr-2 h-4 w-4" />
                        <span>{listing.location}</span>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <Link to={`/exhibition/${listing.id}`}>
                        <Button variant="outline" size="sm">
                          View Details
                        </Button>
                      </Link>
                      <div className="flex space-x-2">
                        <Link to={`/profile/edit-listing/${listing.id}`}>
                          <Button variant="outline" size="sm">
                            <Edit className="h-4 w-4" />
                          </Button>
                        </Link>
                        <Button
                          variant="outline"
                          size="sm"
                          onClick={() => {
                            setDeleteId(listing.id);
                            setShowDeleteConfirm(true);
                          }}
                        >
                          <Trash2 className="h-4 w-4 text-red-500" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Delete Confirmation Modal */}
        {showDeleteConfirm && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="w-full max-w-md rounded-lg bg-white p-6">
              <h3 className="mb-4 text-lg font-semibold text-gray-900">
                Delete Exhibition
              </h3>
              <p className="mb-6 text-gray-600">
                Are you sure you want to delete this exhibition? This action cannot be undone.
              </p>
              <div className="flex justify-end space-x-4">
                <Button
                  variant="outline"
                  onClick={() => {
                    setShowDeleteConfirm(false);
                    setDeleteId(null);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => deleteId && handleDelete(deleteId)}
                  className="bg-red-600 hover:bg-red-700"
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}