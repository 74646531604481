import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, Globe, User, Shield } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/auth-context'; 
import { urlBuilder } from '@/lib/url';
import { supabase } from '@/lib/supabase';

export function Header() {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    async function checkAdminStatus() {
      try {
        if (!user) return;
        
        const { data, error } = await supabase.rpc('is_admin');
        if (!error && data) {
          setIsAdmin(data);
        }
      } catch (err) {
        console.error('Error checking admin status:', err);
        setError(err instanceof Error ? err : new Error('Failed to check admin status'));
      }
    }
    checkAdminStatus();
  }, [user]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
      setError(error instanceof Error ? error : new Error('Failed to sign out'));
    }
  };

  const getAuthRedirectParams = () => {
    const currentPath = location.pathname;
    const validPaths = ['/list-exhibition', '/pricing', '/profile'];
    
    if (validPaths.includes(currentPath)) {
      return { next: currentPath };
    }
    
    return undefined;
  };

  if (error) {
    throw error; // Let the error boundary handle it
  }

  return (
    <header className="border-b border-gray-200 bg-white">
      <div className="container mx-auto px-4">
        <div className="flex h-16 items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="flex items-center">
              <div className="flex h-8 w-8 items-center justify-center rounded bg-blue-600 text-white">
                <Globe className="h-5 w-5" />
              </div>
              <div className="ml-2 flex flex-col">
                <span className="text-xl font-bold text-gray-900">
                  Exhibitions.info
                </span>
                <span className="text-sm text-gray-600">
                  Connecting Global Events
                </span>
               </div>
            </Link>
          </div>

          <nav className="hidden md:flex items-center space-x-8">
            <Link to="/exhibitions" className="text-gray-600 hover:text-gray-900">
              Directory
            </Link>
            <Link to="/about" className="text-gray-600 hover:text-gray-900">
              About
            </Link>
            <Link to="/pricing" className="text-gray-600 hover:text-gray-900">
              Pricing
            </Link>
            <Link to="/contact" className="text-gray-600 hover:text-gray-900">
              Contact
            </Link>
          </nav>

          <div className="flex items-center space-x-4">
            {user ? (
              <>
                {isAdmin && (
                  <Link 
                    to="/admin" 
                    className="flex items-center p-2 text-blue-600 hover:text-blue-700 rounded-md"
                  >
                    <Shield className="h-5 w-5 mr-2" />
                    <span className="text-sm">Admin</span>
                  </Link>
                )}
                <Link 
                  to="/profile" 
                  className="p-2 text-gray-600 hover:text-gray-900 rounded-md" 
                  title="My Profile"
                >
                  <User className="h-5 w-5 mr-2" />
                </Link>
                <Button variant="outline" size="sm" onClick={handleSignOut}>
                  Sign Out
                </Button>
                <Link to="/list-exhibition">
                  <Button size="sm">List Exhibition</Button>
                </Link>
              </>
            ) : (
              <>
                <Link to={urlBuilder.build('/signin', getAuthRedirectParams())}>
                  <Button variant="outline" size="sm">
                    Sign In
                  </Button>
                </Link>
                <Link to={urlBuilder.build('/signup', getAuthRedirectParams())}>
                  <Button size="sm">
                    Sign Up to List Exhibition
                  </Button>
                </Link>
              </>
            )}
            <button className="md:hidden">
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}