import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AuthProvider } from '@/contexts/auth-context';
import { ErrorBoundary } from '@/lib/error-boundary'; 
import App from './App';
import { HeroSection } from '@/components/home/hero-section';
import { FeaturedExhibitions } from '@/components/home/featured-exhibitions';
import { ExhibitionDirectory } from '@/pages/directory';
import { ExhibitionDetail } from '@/pages/exhibition/[id]';
import { ListExhibition } from '@/pages/list-exhibition';
import { SignIn } from '@/pages/auth/sign-in';
import { SignUp } from '@/pages/auth/sign-up';
import { ForgotPassword } from '@/pages/auth/forgot-password';
import { Pricing } from '@/pages/pricing';
import { Contact } from '@/pages/contact';
import { About } from '@/pages/about';
import { ProfilePage } from '@/pages/profile';
import { Privacy } from '@/pages/privacy';
import { Terms } from '@/pages/terms';
import { EditListing } from '@/pages/profile/edit-listing';
import { AdminDashboard } from '@/pages/admin';
import './index.css';

function HomePage() {
  return (
    <main>
      <HeroSection />
      <FeaturedExhibitions />
    </main>
  );
}

function NotFoundPage() {
  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 p-4">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900">Page Not Found</h1>
        <p className="mt-4 text-gray-600">The page you're looking for doesn't exist.</p>
        <a href="/" className="mt-8 inline-block rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700">
          Return Home
        </a>
      </div>
    </div>
  );
}

// Enhanced error handling
const errorHandler = (error: Error | ErrorEvent | PromiseRejectionEvent) => {
  // Ignore certain benign errors
  const message = error instanceof Error ? error.message : 
                 error instanceof ErrorEvent ? error.error?.message : 
                 error.reason?.message;

  if (message?.includes('ResizeObserver') || 
      message?.includes('domQueryService') ||
      message?.includes('Invalid URL') ||
      message?.includes('unsafe-eval') ||
      message?.includes('Content Security Policy')) {
    return;
  }

  // Log other errors
  console.error('Application error:', error);
};

window.addEventListener('error', errorHandler);
window.addEventListener('unhandledrejection', errorHandler);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFoundPage />,
    children: [
      { index: true, element: <HomePage /> },
      { path: 'exhibitions', element: <ExhibitionDirectory /> },
      { path: 'exhibition/:id', element: <ExhibitionDetail /> },
      { path: 'list-exhibition', element: <ListExhibition /> },
      { path: 'signin', element: <SignIn /> },
      { path: 'signup', element: <SignUp /> }, 
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'pricing', element: <Pricing /> },
      { path: 'contact', element: <Contact /> },
      { path: 'about', element: <About /> },
      { path: 'profile', element: <ProfilePage /> },
      { path: 'privacy', element: <Privacy /> },
      { path: 'terms', element: <Terms /> },
      { path: 'profile/edit-listing/:id', element: <EditListing /> },
      { path: 'admin', element: <AdminDashboard /> },
      { path: '*', element: <NotFoundPage /> }
    ]
  }
]);

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <ErrorBoundary>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}