import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Button } from '@/components/ui/button';
import { AlertCircle } from 'lucide-react';

export interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null | unknown;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  public state: State = {
    hasError: false,
    error: null
  };

  public static getDerivedStateFromError(error: unknown): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Ignore certain benign errors
    const message = error.message || '';
    if (message.includes('ResizeObserver') ||
        message.includes('domQueryService') ||
        message.includes('Invalid URL') ||
        message.includes('unsafe-eval') ||
        message.includes('Content Security Policy') ||
        message.includes('Failed to fetch')) {
      return;
    }

    // Log other errors
    console.error('Application error:', error);
    console.error('Error info:', errorInfo);
  }

  private handleRetry = () => {
    this.setState({ hasError: false, error: null });
  };

  private getErrorMessage = (error: unknown): string => {
    if (error instanceof Error && 
        !error.message.includes('domQueryService') &&
        !error.message.includes('Invalid URL') &&
        !error.message.includes('Failed to fetch')) {
      return error.message;
    }
    if (typeof error === 'string') {
      return error;
    }
    return 'An unexpected error occurred';
  };

  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <div className="flex min-h-screen items-center justify-center bg-gray-50 p-4">
          <div className="w-full max-w-md rounded-lg bg-white p-8 text-center shadow-lg">
            <div className="mx-auto mb-4 flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
              <AlertCircle className="h-6 w-6 text-red-600" />
            </div>
            <h2 className="mb-4 text-2xl font-bold text-gray-900">
              Oops! Something went wrong
            </h2>
            <p className="mb-6 text-gray-600">
              {this.getErrorMessage(this.state.error)}
            </p>
            <div className="space-x-4">
              <Button onClick={this.handleRetry}>
                Try Again
              </Button>
              <Button variant="outline" onClick={() => window.location.href = '/'}>
                Return Home
              </Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}