import React, { useEffect, useState } from 'react';
import { Calendar, MapPin, ExternalLink, Clock, Repeat } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { getUpcomingExhibitions } from '@/lib/api';
import type { Exhibition } from '@/types/exhibition';

export function FeaturedExhibitions() {
  const [exhibitions, setExhibitions] = useState<Exhibition[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadExhibitions() {
      try {
        const data = await getUpcomingExhibitions(3);
        setExhibitions(data);
      } catch (err) {
        setError('Failed to load exhibitions');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    loadExhibitions();
  }, []);

  if (loading) {
    return (
      <section className="py-16">
        <div className="container mx-auto px-4">
          <h2 className="mb-8 text-3xl font-bold text-gray-900">Featured Exhibitions</h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="animate-pulse rounded-lg bg-gray-100 p-6">
                <div className="mb-4 h-48 w-full rounded bg-gray-200" />
                <div className="mb-2 h-6 w-3/4 rounded bg-gray-200" />
                <div className="h-4 w-1/2 rounded bg-gray-200" />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <section className="py-16">
        <div className="container mx-auto px-4">
          <div className="rounded-lg bg-red-50 p-4 text-red-800">{error}</div>
        </div>
      </section>
    );
  }

  return (
    <section className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="mb-8 text-3xl font-bold text-gray-900">Featured Exhibitions</h2>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {exhibitions.map((exhibition) => (
            <Link
              key={exhibition.id}
              to={`/exhibition/${exhibition.id}`}
              className="group overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm transition-shadow hover:shadow-md"
            >
              <div className="aspect-video w-full overflow-hidden bg-blue-50">
                {exhibition.image_url ? (
                <img
                  src={exhibition.image_url}
                  alt={exhibition.name}
                  className="h-full w-full object-cover object-center transition-transform duration-300 group-hover:scale-105"
                />
                ) : (
                <div className="flex h-full w-full items-center justify-center">
                  <span className="text-xl font-bold text-gray-900">{exhibition.name}</span>
                </div>
                )}
              </div>
              <div className="p-6">
                <div className="mb-2 flex items-center justify-between">
                  <span className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-sm font-medium ${
                    exhibition.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : exhibition.status === 'postponed'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {exhibition.status.charAt(0).toUpperCase() + exhibition.status.slice(1)}
                  </span>
                  <span className="text-sm font-medium text-blue-600">
                    {exhibition.categories?.name}
                  </span>
                </div>
                <h3 className="mb-2 text-xl font-bold text-gray-900">{exhibition.name}</h3>
                <div className="mb-4 space-y-2">
                  <div className="flex items-center text-gray-600">
                    <Calendar className="mr-2 h-4 w-4" />
                    <span>{exhibition.date}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Clock className="mr-2 h-4 w-4" />
                    <span>{exhibition.duration}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <Repeat className="mr-2 h-4 w-4" />
                    <span>{exhibition.cycle}</span>
                  </div>
                  <div className="flex items-center text-gray-600">
                    <MapPin className="mr-2 h-4 w-4" />
                    <span>{exhibition.venue}, {exhibition.location}</span>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <Button variant="outline" size="sm">
                    Learn More
                  </Button>
                  <ExternalLink className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}