import React from 'react';
import type { Exhibition } from '@/types/exhibition';
import type { AdminUser } from '../types';

interface StatsCardsProps {
  listings: Exhibition[];
  users: AdminUser[];
  auditLogsCount: number;
}

export function StatsCards({ listings, users, auditLogsCount }: StatsCardsProps) {
  return (
    <div className="mb-8 grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
      <div className="rounded-lg bg-white p-6 shadow-sm">
        <h3 className="text-sm font-medium text-gray-500">Total Listings</h3>
        <p className="mt-2 text-2xl font-semibold text-blue-600">{listings.length}</p>
      </div>
      <div className="rounded-lg bg-white p-6 shadow-sm">
        <h3 className="text-sm font-medium text-gray-500">Total Users</h3>
        <p className="mt-2 text-2xl font-semibold text-green-600">{users.length}</p>
      </div>
      <div className="rounded-lg bg-white p-6 shadow-sm">
        <h3 className="text-sm font-medium text-gray-500">Recent Actions</h3>
        <p className="mt-2 text-2xl font-semibold text-purple-600">{auditLogsCount}</p>
      </div>
    </div>
  );
}